@use '../utils/colorTheme.scss' as color;
@use '../utils/breakpoints.scss' as breakpoints;
$color1: #831bbd;
$color2: #2fd0e4;

.container{
    display: flex;
    width: 1200px;
        flex-direction: column;
        text-align: center;
margin-top: 50px;
margin-left: auto;
margin-right: auto;
    .profile{
        display: flex;
        column-gap: 2em;
        .profileInfos{
          
        }
    }
    @include breakpoints.multi_device('phone', 'tablet', 'desktop-sd', 'desktop-hd') {
        .container {
            width: 100% !important;
        }
      }}

.sectionProfil {
    display: flex;
    width: 1200px;
        flex-direction: row;
        margin-top: 5em;
        height: auto;
}
.leftcol {
    width: 30%;
    flex-direction: column;
display: flex;
border: 3px solid;
border-image: linear-gradient(45deg, #831bbd, #2fd0e4) 1;
position: relative;
height: 490px;

}
.titleProfil {
    background: rgb(81,135,212);
background: linear-gradient(90deg, rgba(81,135,212,1) 0%, rgba(57,186,223,1) 54%, rgba(48,206,228,1) 100%);
margin-bottom: 20px;
font-size: 1.3em;
padding: 5px;
padding-bottom: 8px;
font-weight: 600;
}
.titleProduct {
margin-bottom: 20px;
font-size: 1.3em;
padding: 5px;
padding-bottom: 8px;
font-weight: 600;
}
.contentLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 270px;
    justify-content: center;
}
.infoProfil {
    margin-top: 10px;
}
.rightcol {
    width: 70%;
display: flex;
flex-direction: column;
background: #3c3c3c;
padding-bottom: 20px;
}

.PlaceButton {
    position: absolute;
    bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
}
// Button component
.EditButton {
    position: relative;
    padding: 1em 1.5em;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    margin: 1em 0.8em;


    &.type1 {
        color: white;
    }

    // Effect one
    &.type1::after,
    &.type1::before {
        content: '';
        display: block;
        position: absolute;
        width: 20%;
        height: 20%;
        border: 2px solid;
        transition: all 0.6s ease;
        border-radius: 2px;
    }

    &.type1::after {
        bottom: 0;
        right: 0;
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom-color: $color2;
        border-right-color: $color2;
    }

    &.type1::before {
        top: 0;
        left: 0;
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-top-color: $color1;
        border-left-color: $color1;
    }

    &.type1:hover:after,
    &.type1:hover:before {
        width: 100%;
        height: 100%;
    }

}
.edituser {
    width: 1200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    row-gap: 20px;
    h1 {
        margin-top:40px;
    }
 
    input {
        height: 40px;
        padding: 10px;
        background-color: #3c3c3c;
        color: white;
        border: none;
    }
    input:focus {
    
        background-color: #ffffff;
        color: rgb(37, 37, 37);
    }
    button {
        height: 40px;
        padding: 10px;
        background-color: #fcf9f9;
        color: rgb(44, 43, 43);
        border:1px solid white ;
        cursor: pointer;
        width: 20%;
        align-self: center;
        transition: transform 0.5s ease-in-out;
    }
    button:hover {
        transform: scale(1.15);
        background:linear-gradient(45deg, #831bbd, #2fd0e4);
        color: #fdfdfd;
        border: none;
    
    }
}
