@use '../utils/colorTheme.scss' as color;
@use '../utils/breakpoints.scss' as breakpoints;
$color1: #831bbd;
$color2: #2fd0e4;

@-webkit-keyframes scale-in-ver-center {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        opacity: 1
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1
    }
}

@keyframes scale-in-ver-center {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        opacity: 1
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1
    }
}

@-webkit-keyframes pulsate-bck {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes pulsate-bck {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes fade-in-fwd {
    0% {
        -webkit-transform: translateZ(-80px);
        transform: translateZ(-80px);
        opacity: 0
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes fade-in-fwd {
    0% {
        -webkit-transform: translateZ(-80px);
        transform: translateZ(-80px);
        opacity: 0
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}




.artistsCollection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: color.$white;
    row-gap: 2.5em;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
   
    @include breakpoints.device("phone") {
        width : 100%;
        }

    .artistsCard:hover {
        transform: scale(1.1);

        &::before {
            opacity: 0;

        }

        &::after {
            opacity: 0;
        }

    }

    .artistsCard {
        display: flex;
        width: 30%;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        position: relative;
        text-align: center;
        margin-bottom: 2em;
        margin-top: 2em;
        transition: transform .2s;
        /* Animation */




        @include breakpoints.device("desktop-sd") {
            width: 40%;
        }

        @include breakpoints.device("tablet") {
            width: 80%;
        }

        @include breakpoints.device("phone") {
            width: 90%;
        }

        .artistImageLink {
            width: 180px;
            margin-top: 1em;
            margin-bottom: 1em;
            margin-left: auto;
            margin-right: auto;

            @include breakpoints.device("desktop-sd") {
                width: 80%;
            }

            @include breakpoints.device("tablet") {
                width: 80%;
            }

            @include breakpoints.device("phone") {
                width: 100%;
            }

            a {
                text-decoration: none;
                text-align: center;
                position: relative;
                z-index: 1;
                width: 100%;
                text-decoration: inherit;

                img {
                    width: 100px;
                    border-radius: 50%;
                    border: none;
                    transition: border 150ms ease-in-out;
                    margin-left: auto;
                    margin-right: auto;
                }

                img:hover {
                    border: solid 2px color.$white
                }
            }
        }

        .artistInformations {
            font-size: 1.5em;
            text-align: center;
            position: relative;
            z-index: 1;

            margin-bottom: 1em;

            p {
                line-height: 2em;

                span {
                    border-bottom: solid 2px color.$white;
                }

            }

        }



        .artistDescription {
            margin-bottom: 1em;
            font-size: 15px;
            line-height: 22px;
            z-index: 1;
            width: 90%;
            font-weight: 200 !important;
            font-family: "Poppins", sans-serif;
            text-align: justify;

            @include breakpoints.device("desktop-sd") {
                font-size: 1em;
            }
        }

        .artistButton {
            z-index: 1;
            background-color: transparent;
            border-radius: 10px;
            border: solid 2px color.$white;
            width: 40%;
            color: color.$white;
            transition: border 0.5s ease-in-out;
            margin-bottom: 45px;
            top: 34px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            position: relative;


            @include breakpoints.device("phone") {
                width: 50%;
            }
        }

        .artistButton:hover {
            background-color: color.$primary-2;
            border: solid 2px transparent;
        }
    }
}

// Border component
.ContainerBorderStyle {
    position: relative;
    padding: 1em 1.5em;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    font-size: 18px;
    margin: 1em 0.8em;
    text-align: center;


    &.type1 {
        color: white;
    }

    // Effect one
    &.type1::after,
    &.type1::before {
        content: '';
        display: block;
        position: absolute;
        width: 20%;
        height: 20%;
        border: 2px solid;
        transition: all 0.6s ease;
        border-radius: 2px;
    }

    &.type1::after {
        bottom: 0;
        right: 0;
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom-color: $color2;
        border-right-color: $color2;
    }

    &.type1::before {
        top: 0;
        left: 0;
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-top-color: $color1;
        border-left-color: $color1;
    }

    &.type1:hover:after,
    &.type1:hover:before {
        width: 100%;
        height: 100%;
    }

    p {
        font-size: 15px;
        margin-top: 15px;
        margin-bottom: 20px;
        font-weight: 200;
    }


}

.ContainerBorderStyle:hover {
    background-color: #292929;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}

.artistdetails,
.Pseudoname,
.PseudoArtist,
.Firstname {
    font-family: 'Exo 2', sans-serif;
    font-size: 20px;
    text-decoration: none !important;

    .Pseudoname {
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none !important;
        border: none !important;
    }

    .PseudoArtist {

        border: none !important;
    }

    .Firstname {

        border: none !important;
        text-transform: uppercase;

    }
}

.SeeMoreButton {
    position: relative;
    display: block;
    z-index: 1;
    bottom: 0;
    width: 200px !important;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    padding: 0.4em;
    border: none !important;
    border-radius: 50px;
    background: linear-gradient(to right, color.$primary-purple, color.$primary-blue);
    cursor: pointer;
    outline: none;
    font-size: 16px;
    margin-bottom: 30px;
    margin-top: 30px;
    color: white;
    transition: all 0.3s ease-in-out;
}
.SeeMoreButton:hover {
    transform: scale(0.8);
}
