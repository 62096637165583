@use '../utils/colorTheme.scss' as color;
@use '../utils/breakpoints.scss' as breakpoints;

.container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoints.device("phone") {  
        width: 100% !important;
   
       }
       @include breakpoints.device('tablet') {
        width: 100% !important;
       }


   

    .price {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        font-size: 1.5em;
        justify-content: center;
        margin-top: 0.5em;
        margin-bottom: 30px;
        margin-left: 15px;
        margin-right: 15px;

        p {
            flex-direction: row;
            column-gap: 0.5em;
            justify-content: space-around;


            span {
                margin-top: -5px;

                svg {

                    vertical-align: middle;
                }

            }
        }


    }

    .price:nth-of-type(2) {
        svg {
            font-size: 0.8em;
        }
    }

    .linkRules {
        text-decoration: none;
        color: white;


        p {
            text-align: end;
            font-size: 0.8em;
            text-decoration: underline;
            margin-top: 2em;
            text-align: center;

        }

    }
}
.button {
    width: auto;
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
    height: 45px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    border: none !important;
    border-radius: 50px;
    background: linear-gradient(to right, color.$primary-purple, color.$primary-blue);
    cursor: pointer;
    outline: none;
    font-size: 20px;
    color: white;
    transition: all 0.3s ease-in-out;
    animation: pulse-black 2s infinite;
    
    @include breakpoints.device("phone") {  
        width: 370px !important;
   
       }
       @include breakpoints.device('tablet') {
        width: 370px !important;
       }
}

.button:hover {
    transform: scale(0.9);
}