@use 'styles/utils/colorTheme.scss' as color;
@use 'styles/utils/breakpoints.scss' as breakpoints;
$color1: #831bbd;
$color2: #2fd0e4;

body {
    background-color: color.$primary;
    color: color.$white
}

.content {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    row-gap: 2.5em;
    margin-top: 5em;

    @include breakpoints.device("tablet") {
        width: 100%;
        flex-direction: column;
    }


    #tsparticles-content {
        width: 50%;
    }

    .nftContainer{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: nowrap;
        width: 100%;
    }

    .container {
        background-color: color.$primary;
        display: flex;
        align-items: center;
        color: color.$white;
        justify-content: space-evenly;
        width: 30%;
        flex-direction: row;
        margin-top: 2.5%;
        img{
            width: 25%;
            box-shadow: 0px 0px 15px color.$primary-2;
            @include breakpoints.multi_device("phone",'tablet') {

                width: 80%;
                margin-bottom:10% ;

            }
        }

        &[data-content="secondary"] {
            margin-top: 5%;
            width: 100%;

            h3 {
                font-size: 4.5em;
            }

            p {
                font-size: 1.25em;
                line-height: 2em;
                margin-top: 2em;
            }

            @include breakpoints.device("phone") {

                display: flex;
                flex-direction: column;

                h3 {
                    font-size: 2.5em;
                    text-align: center;
                }

                p {

                    font-size: 1em;
                    line-height: 2em;
                    margin-top: 2em;
                    text-align: justify;
                    width: 90%;
                }
            }

            @include breakpoints.device("tablet") {

                display: flex;
                flex-direction: column;

                h3 {
                    font-size: 2.5em;
                    text-align: center;
                }

                p {

                    font-size: 1em;
                    line-height: 2em;
                    margin-top: 2em;
                    text-align: justify;
                    width: 90%;
                }
            }

            @include breakpoints.device("desktop-sd") {

                display: flex;
                flex-direction: column;

                h3 {
                    font-size: 2.5em;
                    text-align: center;
                }

                p {

                    font-size: 1em;
                    line-height: 2em;
                    margin-top: 2em;
                    text-align: justify;
                    width: 90%;
                }
            }

            @include breakpoints.device("desktop-hd") {

                display: flex;
                flex-direction: column;

                h3 {
                    font-size: 2.5em;
                    text-align: center;
                }

                p {

                    font-size: 1em;
                    line-height: 2em;
                    margin-top: 2em;
                    text-align: justify;
                    width: 90%;
                }
            }

            @include breakpoints.device("widescreen") {

                h3 {
                    font-size: 2.5em;
                    text-align: center;
                }

                p {

                    font-size: 1em;
                    line-height: 2em;
                    margin-top: 2em;
                    text-align: justify;
                    width: 90%;
                }
            }
        }

        @include breakpoints.device("phone") {
            width: 100%;
        }

        @include breakpoints.device("tablet") {
            width: 100%;
        }

        @include breakpoints.device("desktop-sd") {
            width: 50%;
        }

        @include breakpoints.device("desktop-hd") {
            width: 50%;
        }

        @include breakpoints.device("widescreen") {
            width: 32.5%;
        }

        .iconService {
            font-size: 2.2em;
            margin-bottom: 15px;
            margin-top: 15px;
        }

        // Border component
        .ContainerBorderStyle {
            position: relative;
            padding: 1em 1.5em;
            border: none;
            background-color: transparent;
            cursor: pointer;
            outline: none;
            font-size: 18px;
            margin: 1em 0.8em;
            text-align: center;


            &.type1 {
                color: white;
            }

            // Effect one
            &.type1::after,
            &.type1::before {
                content: '';
                display: block;
                position: absolute;
                width: 20%;
                height: 20%;
                border: 2px solid;
                transition: all 0.6s ease;
                border-radius: 2px;
            }

            &.type1::after {
                bottom: 0;
                right: 0;
                border-top-color: transparent;
                border-left-color: transparent;
                border-bottom-color: $color2;
                border-right-color: $color2;
            }

            &.type1::before {
                top: 0;
                left: 0;
                border-bottom-color: transparent;
                border-right-color: transparent;
                border-top-color: $color1;
                border-left-color: $color1;
            }

            &.type1:hover:after,
            &.type1:hover:before {
                width: 100%;
                height: 100%;
            }

            p {
                font-size: 15px;
                margin-top: 15px;
                margin-bottom: 20px;
                font-weight: 200;
            }


        }

        .ContainerBorderStyle:hover {
            background-color: #292929;
            -webkit-transition: background-color 1000ms linear;
            -ms-transition: background-color 1000ms linear;
            transition: background-color 1000ms linear;
        }


        .infos {
            margin-top: 2rem;
            padding: 1rem;
            width: 90%;
            border: 2px solid;
            position: relative;


            :first-child {
                text-align: center;
                margin-bottom: 1rem;
                position: relative;
                z-index: 1;
            }

            :last-child {
                text-align: center;
                position: relative;
                z-index: 1;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                background: color.$primary;
                top: -0.3em; // let it overlap a bit to prevent thin line which could appear during scaling in some browsers etc.
                bottom: -0.3em;
                left: 3.25em;
                right: 1em;
                width: 80%;

                @include breakpoints.multi_device('tablet', 'phone') {
                    left: 10%;
                    right: 5%;
                    width: 80%;
                }

                @include breakpoints.multi_device('desktop-sd', 'desktop-hd', 'widescreen') {
                    left: 10.1%;
                    right: 5%;
                    width: 80%;
                }

            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                background: color.$primary;
                left: -0.3em;
                right: -0.3em;
                top: 7.5%;
                height: 85%;

                @include breakpoints.multi_device("tablet", 'desktop-sd', 'desktop-hd', 'widescreen') {
                    top: 7.5%;
                    width: 102%;
                }

            }

        }

    }


}

.faq {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    grid-row-gap: 2.5em;
    row-gap: 2.5em;
    margin-top: 5em;
    
    @include breakpoints.multi_device("phone", 'tablet') {
       flex-direction: column;
    }
    img{
        align-self: center;
        width: 22.5%;
        border: solid 1px white;
        box-shadow: 0px 0px 15px color.$primary-2;
        @include breakpoints.multi_device("phone", 'tablet') {
            width: 70%;
        }
    }
}
.contentTitle {
    width: 60%;

    @include breakpoints.multi_device('tablet', 'phone') {
        width: 100%;
    }

}
.CardDisplay {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
}