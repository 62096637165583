@use '../utils/colorTheme.scss' as color;
@use '../utils/breakpoints.scss' as breakpoints;

@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 0.9;
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(18, 18, 18, 0.85);
  z-index: 3;
  animation: slide-down 0.7s;

  img {

    @include breakpoints.device('phone') {
      width: 70%;
    }

    @include breakpoints.device('tablet') {
      width: 30%;
    }

    @include breakpoints.device('desktop-sd') {
      width: 25%;
    }

    @include breakpoints.device('desktop-hd') {
      width: 25%;
    }
  }
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: black;
  color: color.$white;

  img {
    margin-top: 1em;
    width: 160px;

  }

  @include breakpoints.multi_device('phone', 'tablet', 'desktop-sd', 'desktop-hd') {
    .responsive_header {
      transform: none;
      -webkit-transform: none;
    }
  }

  nav {
    width: 80%;
    text-align: right;

    @include breakpoints.multi_device('phone', 'tablet', 'desktop-sd', 'desktop-hd') {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      background-color: black;
      transform: translateY(-100vh);
      z-index: 100;
      height: 100%;


      .header-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
        font-size: 1.8rem;
      }
    }

    .header-link {
      margin-right: 3rem;
      color: color.$white;
      text-decoration: none;
      font-size: 1em;


    }



    li {
      list-style-type: none;
      display: inline-block;
      margin: 20px 5px;

    }

    li>a {
      color: #333;
      text-decoration: none;
      display: inline-block;
      position: relative;
    }

    li>a::after {
      content: "";
      display: block;
      margin: auto;
      height: 2px;
      width: 0;
      margin-top: 5px;
      background: transparent;
      transition: all 0.3s;

    }

    li>a:hover::after,
    li>a.active-nav::after {
      width: 100%;
      background: linear-gradient(to right, #831bbd, #2fd0e4);
    }



  }

  .header-link-img {
    width: 20%;
  }

  @include breakpoints.multi_device('phone', 'tablet', 'desktop-sd', 'desktop-hd') {
    .header-link-img {
      width: 50%;
    }
  }

  li>button {
    width: 100%;
    background: none;
    color: white;
    cursor: pointer;
    padding: 5px;
    font-size: 1em;

  }

  li>button:hover {
    background: linear-gradient(to right, #831bbd, #2fd0e4);
    border-radius: 20px;
    scale : 1.10;
    transition: scale 0.5s ease-in-out;
  }

  .header-link.admin-icon {
    position: absolute;
    right: 1em;
    top: 1em;
    font-size: 1.5em;
  }

  @include breakpoints.multi_device('phone', 'tablet', 'desktop-sd', 'desktop-hd') {
    .header-link.admin-icon {
      position: relative;
      right: 0;
      top: 0;
    }
  }

  .profil-icon {
    font-size: 0.8em;
  }

  .header-btn {
    padding: 5px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    color: color.$white;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;

    @include breakpoints.multi_device('phone', 'tablet', 'desktop-sd', 'desktop-hd') {
      visibility: visible;
      opacity: 1;
    }
  }
}