.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .buyButton{
        font-size: 22px;
        background: transparent;
        align-self: center;
        padding: 0.5em;
    }
}