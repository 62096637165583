@use '../utils/colorTheme.scss' as color;
@use '../utils/breakpoints.scss' as breakpoints;
$color1: #831bbd;
$color2: #2fd0e4;



.formContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10%;



    .formBorder {
       
        width: 50%;
        padding: 1rem;
        border-radius: 10px;
        position: relative;
        background: linear-gradient(to right, color.$primary-purple, color.$primary-blue);
        padding: 3px;
        align-content: center;
        @include breakpoints.multi_device('phone', 'tablet') {
            width: 90%;
        }

        .form {
            border-radius: 10px;
            border: none;

            position: relative;
            background: #181818;
            color: color.$white;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            text-align: center;
            align-items: center;
            row-gap: 1em;
            animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

            input:focus {
                outline: none !important;
                background-color: rgba(205, 205, 205, 0.15);
                color: color.$white;
                border: none !important;

                &::placeholder {
                    color: color.$white;
                }
            }


            .formName {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 90%;

                @include breakpoints.multi_device('mobile', 'tablet') {
                    width: 90%;
                    flex-direction: column;

                }

                input {
                    width: 45%;

                    @include breakpoints.multi_device('mobile', 'tablet') {
                        width: 100%;
                    }
                }

            }

            .formTitle {
                margin-bottom: 1em;
                @include breakpoints.device("phone") {
                    margin-bottom: 0;
                }
            }

            .formDropdown {
                width: 90%;
                padding: 1em;
                background: none;
                border: none;
                border-bottom-color: currentcolor;
                border-bottom-style: none;
                border-bottom-width: medium;
                color: white;
                border-bottom: 1px solid white;
                border-radius: 0px !important;

                option {
                    background-color: #181818;
                    font-size: 14px;
                    height: 10%;
                }
            }

            .formInput {
                width: 90%;
                padding: 1em;
                background: none;
                border: none;
                border-bottom-color: currentcolor;
                border-bottom-style: none;
                border-bottom-width: medium;
                color: white;
                border-bottom: 1px solid white;
                border-radius: 0px !important;

            }

            textarea {
                resize: none;
                background: #373636 !important;
                border: none !important;
                margin-top: 20px;

                &:focus {
                    outline: none !important;
                    background-color: rgba(205, 205, 205, 0.225);
                    color: color.$white;
                }
            }

            // Button component
            
        }
        .submitButton {
   
            position: relative;
            border: none;
            background-color: transparent;
            cursor: pointer;
            outline: none;
            font-size: 16px;
            margin-left: 1em;
            margin-top: 0.25em;
            margin-bottom: 0.25em;
            color: white;
            padding: 0.5em;

            @include breakpoints.multi_device('phone', 'tablet') {
            font-size: 13px;
            margin-left: 0.5em;
            }
            

            &.type1 {
                color: white;
            }

            // Effect one
            &.type1::after,
            &.type1::before {
                content: '';
                display: block;
                position: absolute;
                width: 20%;
                height: 20%;
                border: 2px solid;
                transition: all 0.6s ease;
                border-radius: 2px;
            }

            &.type1::after {
                bottom: 0;
                right: 0;
                border-top-color: transparent;
                border-left-color: transparent;
                border-bottom-color: white;
                border-right-color: white;
            }

            &.type1::before {
                top: 0;
                left: 0;
                border-bottom-color: transparent;
                border-right-color: transparent;
                border-top-color: white;
                border-left-color: white;
            }

            &.type1:hover:after,
            &.type1:hover:before {
                width: 100%;
                height: 100%;
            }

        }
    }
    
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

}