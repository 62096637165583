$breakpoints-min: (
  "phone": 0px,
  "tablet": 428px,
  "desktop-sd": 769px,
  "desktop-hd": 1025px,
  "widescreen": 1201px 
);
$breakpoints-max: (
  "phone": 427px,
  "tablet": 768px,
  "desktop-sd": 1200px,
  "desktop-hd": 1200px,
  "widescreen": 1440px
);


@mixin device($_key) {
  @media screen and (min-width: map-get($breakpoints-min, $_key)) and (max-width: map-get($breakpoints-max, $_key)) {
    & {
      @content;
    }
  }
}

@mixin multi_device($_keys...) {
  @each $_key in $_keys {
    @media screen and (min-width: map-get($breakpoints-min, $_key)) and (max-width: map-get($breakpoints-max, $_key)) {
      & {
        @content;
      }
    }
  }
}

