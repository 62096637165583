       
    .banner {
        background: #181818;
        color: white;
        height: 50vh;
        justify-content: center;
        align-items: center;
        display: flex;
        overflow: hidden;
        .heroContainer {
            position: relative;
            text-align: center;
            font-family: 'Arial', sans-serif;
        }

    
    }


    @media screen and (max-width:425px) {
        .banner {
            .glitch {
                font-size: 2.5rem!important;
            }
        }
    }