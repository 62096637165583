@use '../utils/colorTheme.scss' as color;
@use '../utils/breakpoints.scss' as breakpoints;


.faqContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: justify;
    justify-content: center;
    align-items: center;
    row-gap: 1.5em;
    
    @include breakpoints.multi_device("phone", 'tablet') {
        width: 80%;
    }
    .faqQuestion{
        width: 100%;
        padding: 1em;
        overflow: hidden;
        background: rgba(0,0,0,0.10);
        border-bottom: solid 1px white;

        .questionTitle{
            display: flex;
            justify-content: space-between;
            svg{
                font-size:1.5em;
            
            }
            p{
                font-weight: 700;
                
            }
            margin-bottom: 2%;
        }
        .contentHided {
            transition: all .3s ease-in-out;
            line-height: 0;
            transform-origin: left top;
            transform: scaleY(0);
        }
    
        .contentShowed {
            line-height: 1.5;
            font-weight: 200;
            transition: all .5s ease-in-out;
            transform-origin: right bottom;
            transform: scaleY(1);
    
    
        }
    }
 
}