@use '../utils/breakpoints.scss' as breakpoints;


.countdown{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    letter-spacing: 5px;
    width: 400px;

    .countdownTimer{
        width: 100%;
        font-size: 35px;
        text-align: center;
        vertical-align: middle;
    }
    .countdownTitle{
        width: 100%;
        font-size: 16px;
        text-align: center;
        margin-top: -10px;
        letter-spacing: 0px;

    }

}