@use '../utils/colorTheme.scss' as color;
@use '../utils/breakpoints.scss' as breakpoints;

.containerCollecTitre {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px #444 solid;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;


    @include breakpoints.multi_device('desktop-sd', 'phone', 'tablet') {
        width: 100%;
        flex-direction: column;
    }

}

.container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoints.multi_device('desktop-sd', 'phone', 'tablet') {
        width: 100%;
        flex-direction: column;
    }

    .artistsCard {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        align-self: flex-start;
        flex-direction: column;
        width: 20%;

        @include breakpoints.multi_device('desktop-sd', 'phone', 'tablet') {
            margin-left: auto;
            margin-right: auto;
            width: 100%;

        }


        >p {
            font-size: 2em;
            font-weight: 600;
            text-decoration: underline;
            margin-bottom: 2em;

            @include breakpoints.device('desktop-sd') {
                font-size: 1.25em;
            }

            @include breakpoints.device('tablet') {
                font-size: 1.25em;
            }
        }

        img {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            box-shadow: 0px 0px 25px -15px color.$white;
            margin-bottom: 2em;
        }

        .InformationArtist {
            display: flex;
            flex-direction: column;
            text-align: justify;
            width: 20%;
            margin-left: 40px;

            .iconLongBio {
                font-size: 2rem;
                align-self: flex-end;
                margin-bottom: 1.5em;
            }

            .iconLongBio:hover {
                color: rgb(68, 171, 249);
                cursor: pointer;
            }


        }


        .socialIcons {
            display: flex;
            justify-content: center;
            font-size: 4em;


            > :last-child {
                color: #1DA1F2;
                margin-left: 2em;

                @include breakpoints.multi_device('phone', 'tablet') {
                    margin-left: 0.2em;
                }
            }


        }
    }

    .collectioncard {
        width: 33%;
    }

    .collection {
        text-align: center;
        width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-direction: row;

        >h2 {
            font-size: 2em;
            text-decoration: underline;
            margin-bottom: 2em;

            @include breakpoints.device('desktop-sd') {
                font-size: 1.25em;
            }

            @include breakpoints.device('tablet') {
                font-size: 1.25em;
            }

        }




    }


}

.artistname {
    font-family: 'Exo 2', sans-serif;
    font-size: 25px;

    @include breakpoints.multi_device('desktop-sd', 'phone', 'tablet') {
        font-size: 18px;
        text-align: center;
    }

}

.artistPseudo {
    font-family: 'Exo 2', sans-serif;
    font-size: 16px;
    color: #ae4cfd;
    text-shadow: #ae4cfd 0px 0px 60px;

    @include breakpoints.multi_device('desktop-sd', 'phone', 'tablet') {
        font-size: 18px;
        text-align: center;
    }

}

.artistDesc {
    font-family: 'Exo 2', sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-top: 20px;

}

.Titrecollection {
    width: 100%;
    font-family: 'Exo 2', sans-serif;
    font-weight: 300;

}

.collectionTri {
    display: flex;
    width: 10%;
    font-family: 'Exo 2', sans-serif;
    justify-content: end;

    @include breakpoints.multi_device('desktop-sd', 'phone', 'tablet') {
        flex-direction: column;
    }
}

.InformationArtist {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 50%;
    margin-left: 40px;

    @include breakpoints.multi_device('desktop-sd', 'phone', 'tablet') {
        width: 100%;
        margin-left: 0px;
        padding-left: 20px;
        padding-right: 20px;

    }
}

.social {

    display: flex;
    flex-direction: column;
    width: 30%;
    text-align: right;
    font-family: 'Exo 2', sans-serif;
    font-weight: 300;

    @include breakpoints.multi_device('desktop-sd', 'phone', 'tablet') {
        margin-top: 30px;
        width: 100%;
        text-align: center;

    }
}

.social h2 {
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    text-transform: uppercase;
    @include breakpoints.multi_device('desktop-sd', 'phone', 'tablet') {
        text-align: center;

    }
}

.socialIcons {
    display: flex;
    justify-content: end;
    font-size: 30px;
    gap: 10px;
    margin-top: 20px;
    @include breakpoints.multi_device('desktop-sd', 'phone', 'tablet') {
        justify-content: center;

    }
}