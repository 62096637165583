@use '../utils/colorTheme.scss' as color;
@use '../utils/breakpoints.scss' as breakpoints;

@-webkit-keyframes scale-in-ver-center {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        opacity: 1
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1
    }
}

@keyframes scale-in-ver-center {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        opacity: 1
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1
    }
}

@-webkit-keyframes pulsate-bck {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes pulsate-bck {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes fade-in-fwd {
    0% {
        -webkit-transform: translateZ(-80px);
        transform: translateZ(-80px);
        opacity: 0
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes fade-in-fwd {
    0% {
        -webkit-transform: translateZ(-80px);
        transform: translateZ(-80px);
        opacity: 0
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

$color1: #831bbd;
$color2: #2fd0e4;
$imgbg: '{${collection.preview}}';

.container {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    gap: 40px;
    
    @include breakpoints.device("phone") {
        display: flex;
        flex-direction: column;
    }

    @include breakpoints.device("tablet") {
        display: flex;
        flex-direction: column;
    }

    @include breakpoints.device("desktop-sd") {
        display: flex;
        flex-direction: column;
    }

    .nftCard {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        flex-direction: column;
        margin-top: 5em;
        margin-bottom: 1em;
        width: 30%;

        &[data-page='artist'] {
            width: 45%;

            @include breakpoints.device('desktop-sd') {
                width: 60%;

                .seeMore {
                    width: 50%;
                }
            }

            @include breakpoints.device('tablet') {
                width: 60%;

                .seeMore {
                    width: 50%;
                }
            }

            @include breakpoints.device('phone') {
                width: 60%;

                .seeMore {
                    width: 50%;
                }
            }

            h2 {
                font-size: 1.5em;
            }

            .seeMore {
                width: 50%;
            }


        }

        @include breakpoints.device("phone") {
            width: 100%;
        }

        @include breakpoints.device("tablet") {
            width: 100%;
        }

        @include breakpoints.device("desktop-sd") {
            width: 100%;
        }



        h2 {
            margin-bottom: 1em;
            text-decoration: underline;
            background: -webkit-linear-gradient(#6c6c6c, #eee);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 2em;
        }

        a {
            width: 100%;
            text-align: center;

            img {
                transition: transform 0.2s;

                &:hover {
                    transform: scale(1.1);
                }
            }



            .preview {
                box-shadow: 0px 0px 47px #ffffff29;
                width: 80%;
            }
        }

        .seeMore {
            width: 30%;
            border: solid 2px white;
            border-radius: 10px;
            margin-top: 2em;
            color: white;
            background-color: #181818;
            transition: background-color 150ms ease-in-out;
            transition: border 150ms ease-in;
            text-align: center;
            padding: 3px;

        }

        .seeMore:hover {
            background-color: rgba(205, 205, 205, 0.225);
            border: solid 2px transparent;
        }
    }
}

.imageNFSQ {
    background-size: cover;
    background-position: center;
    height: 450px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    position: relative;


}

.imageOverlay {
    height: 450px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    border-radius: 19px;
    margin-top: -210px;

}
.h3Inside {
    display: none;
    width: 80%;
    -webkit-text-fill-color: white !important;
    border-left: none !important;
    border-right: none !important;
    border: 3px solid;
    border-image: linear-gradient(45deg, #831bbd, #2fd0e4) 1;
    text-decoration: none !important;
    margin-left: auto;
    margin-right: auto;
    -webkit-animation: scale-in-ver-center .5s cubic-bezier(.25, .46, .45, .94) both;
    animation: scale-in-ver-center .5s cubic-bezier(.25, .46, .45, .94) both;
    font-size: 18px;
    padding: 10px;
    text-align: center;


}
.imageOverlay:hover {
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: rgb(255 255 255 / 35%) 0px 5px 47px !important;
}

.imageOverlay:hover .h3Inside {
    display: block
}

.imageOverlay:hover .SeeMoreButton {
    display: block
}

.h2Inside {
    display: none;
    width: 80%;
    -webkit-text-fill-color: white !important;
    border-left: none !important;
    border-right: none !important;
    border: 3px solid;
    border-image: linear-gradient(45deg, #831bbd, #2fd0e4) 1;
    text-decoration: none !important;
    margin-left: auto;
    margin-right: auto;
    -webkit-animation: scale-in-ver-center .5s cubic-bezier(.25, .46, .45, .94) both;
    animation: scale-in-ver-center .5s cubic-bezier(.25, .46, .45, .94) both;
    font-size: 1.7em !important;
    padding: 10px;
    text-align: center;


}

a:link {
    text-decoration: none !important;
}

// Button component
.SeeMoreButton {
    display: none;
    position: absolute;
    bottom: 0;
    width: 200px !important;
    height: 45px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    padding: 0.4em;
    border: none !important;
    border-radius: 50px;
    background: linear-gradient(to right, color.$primary-purple, color.$primary-blue);
    cursor: pointer;
    outline: none;
    font-size: 18px;
    margin-bottom: 7em;
    color: white;
    -webkit-animation: fade-in-fwd .6s cubic-bezier(.39, .575, .565, 1.000) both;
    animation: fade-in-fwd .6s cubic-bezier(.39, .575, .565, 1.000) both;

}

.animcard3D {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
}
.TiltCard {
    width: 400px !important;
    margin-left: auto !important;
margin-right: auto !important;

}

.card {
    display: grid;
    grid-template-columns: 300px;
    grid-template-rows: 210px 210px 80px;
    grid-template-areas: "image" "text" "stats";
    border-radius: 18px;
    background: white;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.9);
    font-family: roboto;
    text-align: center;
    transition: 0.5s ease;
    margin: 30px;
    width: 300px;
    height: 450px;
    background-position: center;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-top: 50px;
  

}

.cardimage {
    grid-area: image;
    background-image: url('http://localhost:3000/images/previews/pierre-taisne/pierre-taisne-series-1/pts1.gif');
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-size: cover;
}

.cardtext {
    grid-area: text;
    margin: 25px;
}

.cardtext .date {
    color: rgb(255, 7, 110);
    font-size: 13px;
}

.cardtext p {
    color: grey;
    font-size: 15px;
    font-weight: 300;
}

.cardtext h2 {
    margin-top: 0px;
    font-size: 28px;
}

.cardstats {
    grid-area: stats;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background: rgb(255, 7, 110);
}

.cardstats .stat {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
}

.cardstats .border {
    border-left: 1px solid rgb(172, 26, 87);
    border-right: 1px solid rgb(172, 26, 87);
}

.cardstats .value {
    font-size: 22px;
    font-weight: 500;
}

.cardstats .value sup {
    font-size: 12px;
}

.cardstats .type {
    font-size: 11px;
    font-weight: 300;
    text-transform: uppercase;
}

.card:hover {
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);

}

.cardimg {
    width: 300px;
    height: 450px;
    object-fit: cover;
    border-radius: 19px;
}

:root {
    --gradient-shadow: linear-gradient(45deg,
            #fb0094,
            #0000ff,
            #00ff00,
            #ffff00,
            #ff0000,
            #fb0094,
            #0000ff,
            #00ff00,
            #ffff00,
            #ff0000);
}

.shadow {

    position: relative;
    width: 300px;
    height: 450px;
    border-radius: 19px;
    
}
.shadow.Mob {
    width: 300px !important;
    height: 390px;
}
.shadow:before,
.shadow:after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    background: var(--gradient-shadow);
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: animate 20s linear infinite;
    border-radius: 19px;
}

.shadow:after {
    filter: blur(5px);
}

.shadow:hover:after {
    filter: blur(15px);
}

@keyframes animate {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 300% 0;
    }

    100% {
        background-position: 0 0;
    }
}
.nftCardMob {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: row;
}
.cardimgMob {
    width: 300px;
    height: 390px;
    border-radius: 19px;
}
.h2InsideMob {
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
    text-align: center;
}
.ArtistCardMob {
    width: 350px !important;
}