body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #181818;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-button-next,.swiper-button-prev{
  color: white !important;

}
.swiper-button-prev {
  margin-left: 40px;
}
.swiper-button-next {
  margin-right: 40px;
}
.swiper-coverflow {
  width: auto !important;
  height: auto !important;

} 
.swiper-slide img {
  display: block;
  width: 400px;
  height: 550px;

  object-fit: cover;
}
.swiper-3d .swiper-cube-shadow, .swiper-3d .swiper-slide, .swiper-3d .swiper-slide-shadow, .swiper-3d .swiper-slide-shadow-bottom, .swiper-3d .swiper-slide-shadow-left, .swiper-3d .swiper-slide-shadow-right, .swiper-3d .swiper-slide-shadow-top, .swiper-3d .swiper-wrapper 
{
  -moz-transform-style: flat !important;
  transform: rotateY(0deg);
}
.swiper-slide.swiper-slide-visible.swiper-slide-prev  {
  transform: translate3d(0px, 0px, -180px) rotateX(0deg) rotateY(50deg) scale(1) !important;
}
.swiper-slide.swiper-slide-visible.swiper-slide-next  {
  transform: translate3d(0px, 0px, -180px) rotateX(0deg) rotateY(-50deg) scale(1) !important;
}
.swiper-3d, .swiper-3d.swiper-css-mode .swiper-wrapper {
z-index: 99;}
