.container{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    .details{
        width: 100%;
        img{
            width: 50%;
        }
    }
    
}