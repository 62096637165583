@use '../utils/colorTheme.scss' as color;
@use '../utils/breakpoints.scss' as breakpoints;

.artistsCard {
    width: 400px !important;
    display: flex;
    justify-content: center;
    margin-top: 0;
    background-color: rgba(15, 15, 15, 0.8);
    z-index: 99;
    bottom: 0;
    position: absolute;
    flex-direction: row;
    padding: 10px;
    border-bottom-right-radius: 19px;
    border-bottom-left-radius: 19px;
    @include breakpoints.multi_device("desktop-sd","phone","tablet") {
        width: 300px !important;
    }

    a {
        text-decoration: none;
        display: inline-flex;
        justify-content: center;

        .artistInformations {
            display: flex;
            flex-direction: row;
            margin-top: 0;
            padding: 2px;
            color: white;
            background-color: transparent;
            flex-direction: row;
            width: 90%;
            justify-content: center;

            @include breakpoints.device("phone") {
                width: 90%;
            }


            img {
                width: 50px;
                height: 50px;
                border-radius: 70px;
                object-fit: cover;
            }

            
        }

        .minicardImgArtist {
            width: 40%;
            text-align: right;
            flex-direction: row;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .minicardInfoArtist {
            width: 60%;
            padding-top: 15px;
            padding-bottom: 15px;
            text-align: left;
        }

        .PseudoArtist {
            font-family: 'Audiowide', cursive;
            font-weight: bold;
        
        }
    }

}

.InfoSpan {
    margin-left: 20px;
    text-align: left;
}

.artistsCardLarge {
    display: flex;
    justify-content: center;
    margin-top: 0;
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    margin-top: 5%;
    box-shadow: 0px 0px 15px color.$primary-2;
    transition: transform 0.5s ease-in-out;

    a {
        text-decoration: none;
        display: inline-flex;
        justify-content: center;

        .artistInformations {
            display: flex;
            flex-direction: row;
            margin-top: 0;
            padding: 2px;
            color: white;
            background-color: transparent;
            flex-direction: row;
            width: 100%;
            font-size: 1.75em;

            @include breakpoints.device("phone") {
                width: 90%;
            }


            img {
                padding-top: 15px;
                padding-bottom: 15px;
                margin-right: 15px;
                width: 60%;
            }

            span {
                border-bottom: solid 2px color.$white;
                font-size: 1em;

            }
        }

        .minicardImgArtist {
            width: 100%;
            text-align: center;
        }

        .minicardInfoArtist {
            width: 100%;
            padding-top: 15px;
            padding-bottom: 15px;
            text-align: left;
            display: flex;
            flex-direction: column;
            row-gap: 2em;
        }

        .PseudoArtist {
            font-family: 'Ma Shan Zheng', cursive !important;
            font-weight: bold;
        }
    }

}

//background: linear-gradient(90deg, rgba(231, 254, 6, 1) 0%, rgba(59, 254, 255, 1) 100%);