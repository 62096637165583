$color1: #831bbd;
$color2: #2fd0e4;
@use '../utils/colorTheme.scss' as color;

@keyframes showPopup {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes hidePopup {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes activeLine {
    0% {
        height: 5%;
    }

    100% {
        height: 10%;
    }
}

.popupDisplayed {
    display: flex;
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    justify-content: center;
    align-items: center;
    animation: showPopup 0.5s ease-in;


    .popupContent,
    .emailPopup {
        width: 30%;
        height: -moz-fit-content;
        height: fit-content;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        row-gap: 1em;
        padding: 1em;

        .emailContent {
            display: flex;
            flex-direction: column;
            width: 130%;

            .emailInput {
                display: flex;
                justify-content: space-between;
                padding: 0.75em;
                align-items: center;

                input {
                    width: 90%;
                    border-radius: 20px;
                    padding: 1em;
                }
            }

            p {
                font-size: 16px;
            }

        }

        .loaderSpinner {

            width: 20%;
            height: 100%;
        }

        .successIcon {
            width: 10%;
            vertical-align: middle;
        }

        .popupLine {
            border-right: solid 2px rgba(255, 255, 255, 0.178);
            width: 50%;
            height: 5%;
            writing-mode: vertical-rl;
        }

        .popupLineActive {
            border-right: solid 2px white;
            width: 50%;
            height: 10%;
            writing-mode: vertical-rl;
            animation: activeLine 0.5s ease-in;

        }

        .popupMessage {
            display: flex;
            align-items: center;

            .popupSuccessMessage {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            p,
            a {
                width: 100%;
                font-size: 16px;
                color: white;
                text-decoration: underline;
            }
        }

        .popupMessageActive {

            color: rgb(255, 255, 255);


        }
    }
}

.popupHidded {
    display: none;
    animation: hidePopup 0.5s ease-in;

}

.closeBtn {
    display: flex;
    align-self: end;
    position: fixed;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 30px;

    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }
}

.PopupInfos {
    background: linear-gradient(to right, #7f50df, #8843dd, #9338dc, #8843dd, #7f50df);
    padding: 10px;
    font-family: 'Exo 2', sans-serif;

}

.PopupInfosLite {
    font-size: 15px !important;
    font-weight: 300;
}

.ProfButton {
    width: 200px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-size: 16px;
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
    padding: 10px;
    cursor: pointer;

}

.submitButton {
    bottom: 0;
    width: 200px !important;
    height: 45px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    padding: 0.4em;
    border: none !important;
    border-radius: 50px;
    background: linear-gradient(to right, color.$primary-purple, color.$primary-blue);
    cursor: pointer;
    outline: none;
    font-size: 18px;
    color: white;
    animation: transform 0.5 ease-in-out; 

    &:hover{
        transform: scale(1.25);
    }
}