@use '../utils/breakpoints.scss' as breakpoints;


.countdown{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 18px rgb(255 255 255 / 28%);
    border-radius: 10px;
    margin-top: 2em;

    .countdownTimer{
        width: 100%;
        font-size: 3em;
        text-align: center;
        vertical-align: middle;
        @include breakpoints.multi_device('phone','tablet'){
            font-size: 2.5em;

        }
    }
    .countdownTitle{
        width: 100%;
        font-size: 1.5em;
        text-align: center;
        margin-bottom: 1em;
        @include breakpoints.multi_device('phone','tablet'){
            font-size: 1.25em!important;

        }
    }

}