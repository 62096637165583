@use '../utils/colorTheme.scss' as color;
@use '../utils/breakpoints.scss' as breakpoints;

.container {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    
    @include breakpoints.device("widescreen") {  
        height: 65px;
        width: 100%;
        justify-content: space-evenly;

       }
       @include breakpoints.multi_device("phone","tablet","desktop-sd",'desktop-hd') {  
        height: 65px;
        width: 100%;
        justify-content: space-evenly;

       }
    .price {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        font-size: 30px;
        justify-content: space-around;
        margin-left: 0.5em;
        @include breakpoints.multi_device("phone","tablet", "desktop-sd","desktop-hd") {  
 
            margin-left: 1em;
            margin-right: 1em;

           }
        p {
            flex-direction: row;
            column-gap: 0.5em;
            justify-content: space-around;

       
            span {
                margin-top: -5px;
                svg{

                    vertical-align: middle;
                }

            }
        }


    }

    .price:nth-of-type(2){
        svg{
            font-size: 0.8em;
        }
    }

    .linkRules {
        display: none;

    }
}
.button {
    width: 250px;
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
    height: 45px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    border: none !important;
    border-radius: 50px;
    background: linear-gradient(to right, color.$primary-purple, color.$primary-blue);
    cursor: pointer;
    outline: none;
    font-size: 20px;
    color: white;
    transition: all 0.3s ease-in-out;
    animation: pulse-black 2s infinite;

    @include breakpoints.device("widescreen") {  
        width: 370px !important;
        font-size: 18px;

       }
       @include breakpoints.multi_device("phone","tablet", "desktop-sd","desktop-hd") {  
        width: 370px !important;
        font-size: 16px;

       }
}

.button:hover {
    transform: scale(0.9);
}