.container{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 3em;
    .content{
        display: flex;
        flex-direction: column;
        row-gap: 0.8em;
        width: 80%;
        text-align: justify;
        list-style-position: inside;
        p, li,td{
            font-weight: 200;
        }

        a{
            color:white;
            span{
                text-decoration: underline;
            }
            
        }
        ul{
            list-style-type: disc;
        }
        h2{
            text-decoration: underline;
            text-align: start;
        }

        table{
            text-align: center;
            width: 100%;
            thead{
                th{
                    text-align: center;
                    text-decoration: underline;
                    padding-bottom: 1em;

                }
            }
            tbody{
                th{
                    text-align: start;
                }    
            }

            tfoot{
                th{
                    text-align: center;
                    text-decoration: underline;
                }  

                th,td{
                    padding-top: 1em;
                }
            }
        }
    }
}