@use '../utils/colorTheme.scss' as color;

.footer{
    background-color: color.$primary;
    width: 100%;
    color: white;
    text-align: center;
    padding-top: 2.5rem;
    margin-top: 2em;
    margin-bottom: 2em;
    .social{
        a{
            font-size: 1.25em;
            color:white;
            transition: transfrom 0.5s ease-in-out;
            :hover{
                transform: scale(1.1);
            }
            svg{
                font-size: 1.5em;
    
                margin:1.5em 2em
                
            }
        }

    }

    .footerNav{
        font-size: 1em;
        list-style: none;
        li{
            margin: 0.5rem;
            a{
                color:white;
            }
        }
    }
}
