@use '../utils/colorTheme.scss' as color;
@use '../utils/breakpoints.scss' as breakpoints;

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');



.container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10%;

    @include breakpoints.multi-device('phone','tablet'){
        flex-direction: column;
        flex-wrap: wrap;
    }

    img {
        width: 30%;
        box-shadow: 0px 0px 47px color.$primary-2;
        transition: transform 0.2s ease-in-out;
        @include breakpoints.multi-device('phone','tablet'){
            width: 80%;

        }
        &:hover {
            transform: scale(1.1);
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        row-gap: 2em;
        width: 50%;
        @include breakpoints.multi-device('phone','tablet'){
            width: 80%;

        }

        h2 {
            font-size: 8em;
            text-align: center;
            @include breakpoints.multi-device('phone','tablet'){
                font-size: 2.5em;
                width: 100%;
                margin-top: 10%;
    
            }
        }

        p {
            font-size: 2em;
        }

        .seeMore {
            display: flex;
            justify-content: center;
            width: 100%;
            border: solid 2px color.$white;
            border-radius: 10px;
            margin-top: 1em;
            color: color.$white;
            background-color: color.$primary;
            transition: background-color 150ms ease-in-out;
            transition: border 150ms ease-in;
            text-align: center;
            padding: 5px;
            align-self: end;
            font-size: 1.25em;
            cursor: pointer;

            :nth-child(2) {
                transform: rotateX(180deg);
                transform: rotateY(180deg);
            }


            svg {
                font-size: 1.5em;

            }

        }

        .seeMore:hover {
            background-color: color.$primary-2;
            border: solid 2px transparent;
        }
  


   
    }

}

.price{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    p{
        display: flex;
        flex-direction: row;
        column-gap: 0.5em;
    }
}

.artistSection {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 white
	}

	50% {
		transform: scale(1);
        box-shadow: 0px 0px 25px white;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 white;
	}
}

.loader{
    display: flex;
    align-self: center;
    justify-content: center;
    width: 90%;
    border-radius: 10px;
    column-gap: 1em;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 3s infinite;
    div{
        transform: scale(0.8);
    }

    span{
        text-decoration: underline;
    }

    p{
        font-size: 1em!important;
        align-self: center;
    }


}

.success{
    display: flex;
    flex-direction: column;
    column-gap: 2em;
    row-gap: 2em;
    div{
        display: flex;
        column-gap: 2em;

 
        p{
            display: flex;
            justify-content: space-around;
            font-size: 1em!important;
            align-items: center;
     
        }
        svg{
            vertical-align: middle;
            font-size: 2em;
        
            color:white
        }
       
    }

  
}

