
@keyframes animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.imageIsLoading{
    display: none!important;

}

.imageLoaded{
    display: block;
    animation: animate 0.5s ease-in;

}
